import styles from './MainLayout.module.scss';
import Nav from "./Nav";
import Footer from "./Footer";
import {ContentLayout} from "./ContentLayout";

const MainLayout = ({ children }) => {
    return (
        <div className={styles.mainLayout}>
            <Nav />
            <ContentLayout>
                {children}
            </ContentLayout>
            <Footer />
        </div>
    );
}

export { MainLayout }