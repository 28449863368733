let data = [
{
        id: 1,
        question: "What is VP Customs?",
        answer: "VP Customs specializes in building custom remote-controlled cars tailored to each customer's specifications. We handle the entire process, from design to delivery, based on your input."
    },
    {
        id: 2,
        question: "How do I place an order for a custom RC car?",
        answer: "To place an order, fill out the inquiry form on our website with your desired specifications. Afterward, we will communicate directly via email to finalize the details and arrange payment."
    },
    {
        id: 3,
        question: "Can I purchase an RC car directly from the website?",
        answer: "No, the purchase process is not completed directly through the website. Once you submit your specifications using our form, we will handle the rest through direct email communication."
    },
    {
        id: 4,
        question: "How is the price of my custom RC car determined?",
        answer: "Prices vary depending on the customization options you choose. We will provide a detailed quote based on your specifications. Prices are in Euros (€) and include VAT."
    },
    {
        id: 5,
        question: "Do you ship internationally?",
        answer: "Yes, we primarily ship within Germany, but international shipping is also available upon request. Shipping costs will vary based on your location."
    },
    {
        id: 6,
        question: "Is shipping free?",
        answer: "Shipping within Germany is free. For international customers, shipping costs will be calculated based on the destination and communicated during the order process."
    },
    {
        id: 7,
        question: "What payment methods do you accept?",
        answer: "We accept payments via PayPal or bank transfer. These payment methods will be discussed and arranged after the initial order inquiry."
    },
    {
        id: 8,
        question: "What is the payment process?",
        answer: "Once your order is confirmed, we require a 50% down payment to start building your RC car. After completion, we will send you photos of the finished product, and you will be required to pay the remaining 50% before the car is shipped."
    },
    {
        id: 9,
        question: "Can I cancel my order after placing it?",
        answer: "Once the 50% down payment is made, cancellations are no longer possible. Custom products are built specifically for you, so they cannot be canceled or returned."
    },
    {
        id: 10,
        question: "Do you accept returns?",
        answer: "Due to the custom nature of our products, we do not accept returns. However, if the car has defects or quality issues, we offer repair services or replacement parts depending on the issue."
    },
    {
        id: 11,
        question: "What happens if my RC car arrives defective?",
        answer: "If your custom-built RC car arrives defective, we will work with you to resolve the issue. Depending on the situation, we may offer repair services or send replacement parts. In some cases, we may cover the cost."
    },
    {
        id: 12,
        question: "Is there a warranty on custom RC cars?",
        answer: "Each custom RC car comes with a list of limitations on liability and proper care instructions. Please review this document to understand the warranty coverage. If needed, you can request this document before placing an order."
    },
    {
        id: 13,
        question: "Are there age restrictions for placing orders?",
        answer: "Yes, only individuals aged 18 and older are permitted to place orders for custom RC cars."
    },
    {
        id: 14,
        question: "What happens if I don't pay the final balance for my order?",
        answer: "If the final balance is not paid after the car is completed, we will not ship the product. Timely payment is required for us to release your custom RC car."
    },
    {
        id: 15,
        question: "Can I request a specific delivery date for my RC car?",
        answer: "While we do our best to accommodate specific delivery requests, the manufacturing time depends on the complexity of your custom build. We will provide an estimated delivery timeframe during the order process."
    },
    {
        id: 16,
        question: "What is the estimated delivery time for a custom-built RC car?",
        answer: "Delivery times vary based on the complexity of the custom build and your location. We will give you an estimated completion and shipping timeline when confirming the order."
    },
    {
        id: 17,
        question: "Can I order replacement parts for my custom RC car?",
        answer: "Yes, we offer spare parts and repairs for our custom RC cars. Contact us if you need replacement parts, and we will assist you."
    },
    {
        id: 18,
        question: "What laws govern my purchase from VP Customs?",
        answer: "All transactions and disputes are governed by the laws of Germany. Any legal disputes will be resolved under German jurisdiction."
    },
    {
        id: 19,
        question: "What should I do if I need to modify my order?",
        answer: "If you need to modify your order, please contact us as soon as possible. However, modifications may not be possible once the build has started, depending on the changes required."
    },
];

export { data }
