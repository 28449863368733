import styles from "./Legal.module.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";

export default function Disclaimer() {
    return (
        <MainLayout>
           <Header main="Disclaimer" sub="Last updated on October 13th 2024" />
                <div className={styles["topic-container"]}>
                    No claims, representations or warranties, whether expressed or
                    implied, are made by our companies as to the safety of any of our
                    companies' products.
                    <br />
                    Furthermore, our company accepts no liability whatsoever for the
                    safety of any of our companies' products.
                    <br />
                    <br />
                    Our LIMITED WARRANTY covers defects in workmanship and materials for a
                    period of one year from the date of first retail purchase. During the
                    warranty period, VP Customs will replace or repair, at its sole
                    option, any defective product returned to us, by its original
                    purchaser.
                    <br />
                    <br />
                    This warranty does not cover problems that result from abuse,
                    accident, misuse, or problems with electrical power. It does not cover
                    uses not in accordance with the instruction manual. It does not cover
                    commercial use of the product. It specifically excludes products for
                    which VP Customs has not received payment.
                    <br />
                    <br />
                    THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS WHICH MAY VARY FROM
                    COUNTRY TO COUNTRY. VP CUSTOMS RESPONSIBILITY FOR DEFECTS IN MATERIAL
                    AND WORKMANSHIP SHALL BE LIMITED TO REPAIR AND/OR REPLACEMENT AS SET
                    FORTH IN THIS WARRANTY. ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING,
                    BUT NOT LIMITED TO ANY IMPLIED WARRANTIES AND WARRANTIES OF
                    MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED.
                    <br />
                    WE DO NOT ACCEPT LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, OR
                    FOR ANY THIRD-PARTY CLAIMS FOR DAMAGES AGAINST THE RETAIL PURCHASER OF
                    THIS PRODUCT. COUNTRIES / STATES DO NOT ALLOW THE EXCLUSION OR
                    LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS MAY NOT
                    APPLY TO YOU. All our products are built as best as possible and will
                    always use the best parts. While we promise, that the performance will
                    always have the exact same level, the visuals might vary slightly due
                    to the fact that the cars are hand built and our partners might change
                    their visuals slightly.
                    <br />
                    <br />
                </div>
            </MainLayout>
    );
}
