import {Link} from "react-router-dom";
import styles from "./ButtonStyles.module.scss";

const ButtonLink = ({ text, target }) => {
    return (
        <Link to={target}>
            <div className={`${styles.standardButton} ${styles.drawingButton}`}>
                {text}
            </div>
        </Link>
    )
}

export { ButtonLink };
