import styles from './ButtonStyles.module.scss';

const StandardButton = ({ text, action }) => {
    return (
        <button className={`${styles.standardButton} ${styles.drawingButton}`} onClick={action}>
            {text}
        </button>
    )
}

export { StandardButton };