import styles from "./NoPage.module.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";
import {ButtonLink} from "../components/controls/ButtonLink";

export default function NoPage() {
    return (
        <MainLayout>
            <Header main="Page not found." />
                <div className={styles["status-main-content"]}>
                    <div className={styles["status-content"]}>
                        This page does not exist.<br/>
                        Check if the <strong>URL</strong> you have entered is correct, 
                        or try navigating to the page using <strong>links and buttons</strong>.<br />
                        If you like you can:
                    </div>
                    <div className={styles["status-buttons"]}>
                        <ButtonLink text="Go to homepage" target="/home" />
                        <div className={styles["alternative"]}>
                            or
                        </div>
                        <ButtonLink text="Continue shopping" target="/products" />
                    </div>
                </div>
        </MainLayout>
    )

}
