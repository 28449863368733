import styles from "./Legal.module.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";

export default function Privacy() {
    return (
        <MainLayout>
            <Header main="Privacy Policy" sub="Last updated on October 13th 2024" />
                <div className={styles["topic-container"]}>
    <p>At VP Customs, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you interact with us.</p>

    <h3>1. What Information Do We Collect?</h3>
    <p>We collect the following information when you use our services:</p>
    <ul>
        <li><strong>Email Address:</strong> Collected when you submit a contact request through our website.</li>
        <li><strong>Contact Request Data:</strong> Any information you provide in your contact message, such as your name, phone number, or other details necessary to address your inquiry.</li>
        <li><strong>Product Request Form Data:</strong> Information you provide when filling out our custom-built RC car request form, such as your preferences, specifications, and any relevant details needed to process your request.</li>
    </ul>

    <h3>2. How Do We Use Your Information?</h3>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
        <li><strong>To respond to your inquiries:</strong> We use contact request data to address your questions or concerns.</li>
        <li><strong>Custom product requests:</strong> We use the product request form data to process and build custom RC cars based on your specifications.</li>
        <li><strong>Communication:</strong> We may use your email address to communicate with you regarding your request or to provide important updates about our services or products.</li>
    </ul>

    <h3>3. How Do We Store and Protect Your Information?</h3>
    <p>We take reasonable precautions to protect your personal information. The following information is stored in our database:</p>
    <ul>
        <li>Email addresses</li>
        <li>Contact request data</li>
        <li>Product request form data</li>
    </ul>
    <p>We use industry-standard measures to protect this data from unauthorized access. Other information, such as detailed order processing data, is handled manually and not stored electronically.</p>

    <h3>4. Sharing of Your Information</h3>
    <p>We do not sell, rent, or share your personal information with third parties, except in the following circumstances:</p>
    <ul>
        <li><strong>With service providers:</strong> We may share your information with trusted third-party service providers who assist us in responding to your requests or fulfilling product orders.</li>
        <li><strong>Legal requirements:</strong> We may disclose your information if required by law or to protect our rights, safety, or property.</li>
    </ul>

    <h3>5. Your Privacy Rights</h3>
    <p>You have the following rights concerning your personal information:</p>
    <ul>
        <li><strong>Access and Update:</strong> You can request access to or update the personal information we hold about you.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your personal information at any time.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="mailto:contact@vp-customs.com">contact@vp-customs.com</a>.</p>

    <h3>6. How Long Do We Keep Your Information?</h3>
    <p>We retain your email address, contact request data, and product request form data only as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws.</p>

    <h3>7. Changes to This Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. Any changes will be reflected with an updated "Effective Date" at the top of this policy. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>

    <h3>8. Contact Us</h3>
    <p>If you have any questions or concerns about this Privacy Policy or the way we handle your personal information, please contact us at:</p>
    <p>
        VP Customs<br/>
        Nelkenstraße 6, 72411 Bodelshausen<br/>
        <a href="mailto:contact@vp-customs.com">contact@vp-customs.com</a><br/>
    </p>


                </div>
        </MainLayout>
    );
}
