import styles from './Header.module.scss';

const Header = ({ main, sub}) => {
    return (
        <div className={styles.headerContainer}>
            <h2 className={styles.mainHeader}>{main}</h2>
            {sub && <h3 className={styles.subHeader}>{sub}</h3>}
        </div>
    )
}

export { Header };