import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Products from "./pages/Products"
import Contact from "./pages/Contact"
import Faq from "./pages/Faq"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"
import Disclaimer from "./pages/Disclaimer"
import NoPage from "./pages/NoPage"

import ScrollToTop from "./components/ScrollToTop"


import "./styles/inputs.scss";
import "./styles/checkboxes.scss";

import "./styles/utility_styles.scss";

function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route index element={<Home />} />
                    <Route  path="/home" element={<Home />}/>
                    <Route  path="/about" element={<About />}/>
                    <Route  path="/products" element={<Products />}/>
                    <Route  path="/contact" element={<Contact />}/>
                    <Route  path="/faq" element={<Faq />}/>
                    <Route  path="/privacy" element={<Privacy />}/>
                    <Route  path="/terms" element={<Terms />}/>
                    <Route  path="/disclaimer" element={<Disclaimer />}/>

                    <Route  path="*" element={<NoPage />}/>
                </Routes>
            </BrowserRouter>
        </> 

    );
}

export default App;
