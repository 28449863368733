import styles from "./About.module.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";
import {ButtonLink} from "../components/controls/ButtonLink";
import {useEffect, useRef} from "react";

export default function About() {
    const images = [
        "/images/about_carousel_1.jpg",
        "/images/about_carousel_2.jpg",
        "/images/about_carousel_3.jpg",
        "/images/about_carousel_4.jpg",
        "/images/about_carousel_5.jpg",
        "/images/about_carousel_6.jpg",
        "/images/about_carousel_7.jpg",
        "/images/about_carousel_8.jpg",
        "/images/about_carousel_9.jpg",
        "/images/about_carousel_10.jpg",
    ];

    const imageRefs = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                }
            });
        }, {
            threshold: 0.4,
            }
        );

        imageRefs.current.forEach((img) => {
            if (img) observer.observe(img);
        });
    }, [])

    return (
        <MainLayout>
            <Header main="What we do."/>
            <div className={styles.topContainer}>
                <div className={styles.leftContainer}>
                    <h3 className={styles.aboutHeader}>Custom building your dream RC car</h3>
                    <div className={styles.aboutText}>
                        At VP Customs, we specialize in high-quality custom RC cars.
                        Every car is built and set-up based on your specifications, to matches exactly
                        what you want in an RC car. Every car is delivered to you ready to run so you can
                        just focus on driving.
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.rightHeader}>Want to know more?</div>
                    <ButtonLink text="FAQ" target="/faq" />
                    <ButtonLink text="Contact us" target="/contact"/>
                </div>
            </div>

            <div className={styles.galleryContainer}>
                <div className={styles.galleryHeader}>Our gallery.</div>
                <div className={styles.imageContainer}>
                    {images.map((src, index) => (
                        <img
                            key={index}
                            ref={(element) => (imageRefs.current[index] = element)}
                            src={src}
                            alt={`Slide ${index + 1}`}
                            className={`${styles.galleryImage} ${index < (images.length / 2) ? styles.left : styles.right}`}
                        />
                    ))}
                </div>
            </div>
        </MainLayout>
    );
}
