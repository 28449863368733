import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as UpIcon } from "../../assets/icons/up.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as TiktokIcon } from "../../assets/icons/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { addNewsletterEntry } from "../../services/api";

export default function Footer() {
    const footerEmailRegEx =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    const [emailInput, setEmailInput] = useState("");
    const [emailInfo, setEmailInfo] = useState({
        message: "Subscribe to our newsletter",
        style: "default",
    });

    const handleInputChange = (event) => {
        setEmailInput(event.target.value);
    };

    const handleEmailConfirmation = async (event) => {
        event.preventDefault();
        if (emailInput < 1) {
            event.preventDefault();
            setEmailInfo({
                message: "Please enter an email address",
                style: "error",
            });
        } else if (!footerEmailRegEx.test(emailInput)) {
            event.preventDefault();
            setEmailInfo({
                message: "That's not an email address :(",
                style: "error",
            });
        } else {
            try {
                const response = await addNewsletterEntry(emailInput);

                if (response.success) {
                    setEmailInfo({
                        message: response.message,
                        style: "success",
                    });
                } else {
                    // Error if response is broken
                    console.log(`Error: ${response.message}`);
                    setEmailInfo({
                        message: response.message,
                        style: "error",
                    });
                }
            } catch (error) {
                // Error if connection doesn't work
                console.log(`Error 2: ${error.message}`);
                setEmailInfo({
                    message: error.response.data.message,
                    style: "error",
                });
            }
        }
    };

    return (
        <>
            <div className={styles["footer-section"]}>
                <a className={styles["back-to-top"]} href="#top">
                    <UpIcon className={styles["footer-arrow"]} />
                </a>
                <footer className={styles.footer}>
                    <span className={styles["footer-logo"]}>
                        <Link to="/home">
                            <img
                                className={styles["logo-image"]}
                                src="/images/Logo_white.png"
                                alt="VP Customs logo"
                            />
                        </Link>
                    </span>
                    <div className={styles["footer-nav"]}>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/about">
                                <span className={styles["footer-nav-link"]} id={styles.active}>
                                    About
                                </span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/products">
                                <span className={styles["footer-nav-link"]}>Products</span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/contact">
                                <span className={styles["footer-nav-link"]}>Contact</span>
                            </Link>
                        </span>
                        <span className={styles["footer-nav-item"]}>
                            <Link to="/faq">
                                <span className={styles["footer-nav-link"]}>FAQ</span>
                            </Link>
                        </span>
                    </div>
                    <div className={styles["footer-text"]}>
                        Producing high quality, hand built RC cars based on your dreams.
                        Just tell us what you want - we’ll take care of the rest so you can
                        focus on driving.
                    </div>
                    <div className={styles["social-section"]}>
                        <div className={styles["social-media-section"]}>
                            <a
                                href="https://www.instagram.com/vpcustomsrc/"
                                className={styles["social-item"]}
                            >
                                <InstagramIcon className={styles.socialIcon}/>
                            </a>
                            <a
                                href="https://www.tiktok.com/@vpcustomsrc"
                                className={styles["social-item"]}
                            >
                                <TiktokIcon className={styles.socialIcon}/>
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCx3AxpWQ_foi_vP2On-DHlQ"
                                className={styles["social-item"]}
                            >
                                <YoutubeIcon className={styles.socialIcon}/>
                            </a>
                        </div>
                        <div className={styles["newsletter-section"]}>
                            <div className={styles.subscribe}>{emailInfo.message}</div>
                            <form className={`${styles.footerFormGroup}`}>
                                <input
                                    onChange={handleInputChange}
                                    maxLength="250"
                                    type="text"
                                    className="form-field footer-form-field"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                />
                                <label htmlFor="name" className="form-label footer-form-label">
                                    Email
                                </label>
                                <button
                                    onClick={handleEmailConfirmation}
                                    type="submit"
                                    className={`form-confirmation-button ${styles["footer-confirmation-button"]}`}
                                >
                                    Confirm
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className={styles["legal-section"]}>
                        <Link to="/privacy">
                            <span className={styles["legal-item"]}>Privacy Policy</span>
                        </Link>
                        <Link to="/terms">
                            <span className={styles["legal-item"]}>Terms & Conditions</span>
                        </Link>
                        <Link to="/disclaimer">
                            <span className={styles["legal-item"]}>Disclaimer</span>
                        </Link>
                    </div>
                </footer>
            </div>
        </>
    );
}
