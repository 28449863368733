import { Link } from "react-router-dom";

import styles from "./Contact.module.scss";
import { useState } from "react";

import { addContactRequest } from "../services/api";
import {MainLayout} from "../components/layout/MainLayout";
import {StandardButton} from "../components/controls/StandardButton";
import {Header} from "../components/layout/Header";

export default function Contact() {
    const [emailInput, setEmailInput] = useState("");
    const [topicInput, setTopicInput] = useState("");
    const [textInput, setTextInput] = useState("");

    const [emailError, setEmailError] = useState("");
    const [topicError, setTopicError] = useState("");
    const [textError, setTextError] = useState("");

    const [success, setSuccess] = useState("");

    const handleEmailInput = (event) => {
        setEmailInput(event.target.value);
    };

    const handleTopicInput = (event) => {
        setTopicInput(event.target.value);
    };

    const handleTextInput = (event) => {
        setTextInput(event.target.value);
    };

    const handleConfirmation = async (event) => {
        event.preventDefault();
        let inputValid = true;
        const emailRegEx =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

        if (emailInput.length === 0) {
            setEmailError("Please enter your email address");
            inputValid = false;
        } else if (!emailRegEx.test(emailInput)) {
            setEmailError("That's not an email address :(");
            inputValid = false;
        } else {
            setEmailError("");
        }

        if (topicInput.length === 0) {
            setTopicError("Please enter a topic");
            inputValid = false;
        } else {
            setTopicError("");
        }

        if (textInput.length === 0) {
            setTextError("Please enter some text");
            inputValid = false;
        } else {
            setTextError("");
        }

        if (inputValid) {
            const request = {
                email: emailInput,
                topic: topicInput,
                text: textInput,
            };

            try {
                const response = await addContactRequest(request);

                if (response.success) {
                    setSuccess(response.message);
                } else {
                    // Error if response is broken
                    console.log(`Error: ${response.message}`);
                    setSuccess("There was a problem while adding your request");
                }
            } catch (error) {
                    // Error if connection doesn't work
                    console.log(`Error 2: ${error.message}`);
                    setSuccess("There was a problem while adding your request");
            }
        }
    };
    return (
        <MainLayout>
            <Header
                main="Get in touch."
                sub="Ask your questions, connect with us or just say 'hi'."
            />
            <form className={styles["contact-form"]}>
                <div className={styles["contact-group"]}>
                    <div className={styles["contact-problem"]}>{emailError}</div>
                    <div className="form-group form-group-v1 contact-input">
                        <input
                            onChange={handleEmailInput}
                            maxLength="250"
                            type="text"
                            className="form-field form-field-v1"
                            placeholder="email"
                            name="email"
                            id="email"
                        />
                        <label htmlFor="name" className="form-label form-label-v1">
                            Your Email Address
                        </label>
                    </div>
                </div>
                <div className={styles["contact-group"]}>
                    <div className={styles["contact-problem"]}>{topicError}</div>
                    <div className="form-group form-group-v1 contact-input topic-input">
                        <input
                            onChange={handleTopicInput}
                            maxLength="250"
                            type="text"
                            className="form-field form-field-v1"
                            placeholder="topic"
                            name="topic"
                            id="topic"
                        />
                        <label htmlFor="name" className="form-label form-label-v1">
                            Your Topic
                        </label>
                    </div>
                </div>
                <div className={styles["contact-group"]}>
                    <div className={styles["contact-problem"]}>{textError}</div>
                    <textarea
                        onChange={handleTextInput}
                        className={styles["contact-textarea"]}
                        id="text"
                        name="text"
                        cols="30"
                        rows="10"
                        placeholder="Your text"
                    ></textarea>
                </div>
                <div className={styles.confirmationSection}>
                    <div className={`${styles.contactResult} ${success ? styles.success : ''}` }>{success}</div>
                    <StandardButton text="Contact us" action={(event) => handleConfirmation(event)}/>
                    <div className={styles["faq-section"]}>
                        Click{" "}
                        <Link to="/faq">
                            <span className={styles["faq-link-bottom"]}>here</span>
                        </Link>{" "}
                        to check if your question has been asked before.
                    </div>
                </div>
            </form>
        </MainLayout>
    );
}
