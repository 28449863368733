import styles from "./Legal.module.scss";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";

export default function Terms() {
    return (
        <MainLayout>
            <Header main="Terms & Conditions" sub="Last updated on October 13th 2024" />
            <div className={styles["topic-container"]}>
                <h3>1. Introduction</h3>
                <p>
                    These Terms and Conditions ("Terms") govern the relationship between
                    you, the customer ("Customer"), and VP Customs ("Company," "we,"
                    "us," or "our"). By accessing or utilizing our services, you hereby
                    agree to comply with and be bound by these Terms. If you do not
                    agree with these Terms, you shall not access or utilize our
                    services.
                </p>

                <h3>2. Company Information</h3>
                <p>
                    <strong>Company Name:</strong> VP Customs
                </p>
                <p>
                    <strong>Address:</strong> Nelkenstraße 6, 72411 Bodelshausen,
                    Germany
                </p>
                <p>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:contact@vp-customs.com">contact@vp-customs.com</a>
                </p>

                <h3>3. Products and Ordering</h3>
                <p>
                    We are engaged in the design and manufacture of custom-built RC cars
                    ("Products"). The purchase of Products shall not occur directly
                    through our website. Customers are required to complete a
                    specification form to submit their requests. Subsequent
                    communication shall be conducted via email.
                </p>
                <p>
                    Pricing for the Products is variable and contingent upon customer
                    specifications. Value Added Tax (VAT) is included in all pricing.
                </p>

                <h3>3.1 Payment Methods</h3>
                <p>
                    Payments may be rendered via PayPal or bank transfer. Payment
                    processing shall not occur directly on our website, but will be
                    facilitated during direct communication with the Customer.
                </p>
                <p>All prices are denominated in Euros (€).</p>

                <h3>3.2 Shipping</h3>
                <p>
                    Shipping services are primarily available within Germany, though
                    international shipping may be arranged upon customer request.
                    Shipping costs are not included in the Product price and shall be
                    communicated based on the Customer's location. Shipping within
                    Germany is provided at no additional cost.
                </p>

                <h3>4. Returns and Repairs</h3>
                <p>
                    Given the custom nature of our Products, we do not accept returns
                    once payment has been received and the Product has been delivered.
                    We do, however, provide spare parts and repair services for
                    defective Products as deemed necessary.
                </p>

                <h3>4.1 Warranty and Liability</h3>
                <p>
                    Each custom RC car is accompanied by a list of limitations on
                    liability. Customers may request this information prior to purchase.
                    We will bear the costs for repairs or replacement parts resulting
                    from defects, contingent upon the nature of the issue.
                </p>

                <h3>5. User Responsibilities</h3>
                <p>
                    All fraudulent activities conducted on our website are strictly
                    prohibited and will be prosecuted to the fullest extent permissible
                    under law. All content displayed on our website is either owned by
                    us or is royalty-free.
                </p>

                <h3>6. Governing Law</h3>
                <p>
                    Any disputes arising from or in connection with these Terms shall be
                    governed by and construed in accordance with the laws of Germany.
                </p>

                <h3>7. Order Cancellation</h3>
                <p>
                    Customers may place orders until final confirmation in the form of a
                    down payment of 50%. Following the provision of photographic
                    evidence of the completed Product, the Customer shall remit the
                    final payment. Post down payment, cancellation of the order shall
                    not be permitted.
                </p>
                <p>
                    In the event that a Customer fails to remit the final payment, the
                    Product shall not be shipped.
                </p>

                <h3>8. Age Restrictions</h3>
                <p>
                    Orders may only be placed by individuals who are at least 18 years
                    of age.
                </p>

                <h3>9. Force Majeure</h3>
                <p>
                    We shall not be liable for any failure to perform our obligations
                    under these Terms if such failure results from events beyond our
                    reasonable control, including, but not limited to, natural
                    disasters, acts of government, war, riots, or any other unforeseen
                    circumstances that could not have been avoided with reasonable
                    diligence.
                </p>

                <h3>10. Amendments to the Terms</h3>
                <p>
                    We reserve the right to modify these Terms at any time. Any
                    amendments will be effective immediately upon being posted on our
                    website and shall apply to all transactions occurring after the
                    posting date.
                </p>

                <h3>11. Contact Us</h3>
                <p>
                    If you have any inquiries regarding these Terms and Conditions,
                    please do not hesitate to contact us at{" "}
                    <a href="mailto:contact@vp-customs.com">contact@vp-customs.com</a>.
                </p>
            </div>
        </MainLayout>
    );
}
