import styles from "./Faq.module.scss";
import { useState } from "react";

import { data } from "../data/faqData.js";
import {MainLayout} from "../components/layout/MainLayout";
import {Header} from "../components/layout/Header";
import {ButtonLink} from "../components/controls/ButtonLink";

import { ReactComponent as UpIcon } from "../assets/icons/up.svg";
import { ReactComponent as DownIcon } from "../assets/icons/down.svg";



export default function Faq() {
    // Init question data
    const buildQuestionData = () => {
        return data.map((item) => ({
            ...item,
            open: false,
            visible: true,
        }));
    };


    // Set questionData
    const [questionData, setQuestionData] = useState(() => buildQuestionData());

    const handleOpenQuestion = (question) => {
        setQuestionData((prevData) =>
            prevData.map((item) =>
                item.id === question.id ? { ...item, open: !item.open } : item,
            ),
        );
    };

    const handleSearchInput = (event) => {
        let value = event.target.value.toLowerCase();
        setQuestionData((prevData) =>
            prevData.map((item) => {
                if (value.length > 0) {
                    const matchesSearch =
                        item.question.toLowerCase().includes(value) ||
                        item.answer.toLowerCase().includes(value);

                    return { ...item, visible: matchesSearch };
                } else {
                    return { ...item, visible: true };
                }
            }),
        );
    };

    return (
        <MainLayout>
            <Header main="FAQ." sub="Has your question been asked before?" />
            <div className={styles["faq-wrapper"]}>
                <div className={styles["search-section"]}>
                    <div className={styles["search-group"]}>
                        <div className={styles["search-problem"]}></div>
                        <div className="form-group form-group-v1 contact-input">
                            <input
                                maxlength="250"
                                onChange={handleSearchInput}
                                type="text"
                                className="form-field form-field-v1"
                                placeholder="search"
                                name="search"
                                id="search"
                            />
                            <label htmlFor="search" className="form-label form-label-v1">
                                Type what you are looking for...
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles["faq-section"]}>
                    {questionData
                        .filter((item) => item.visible)
                        .map((item) => (
                            <div className={styles["faq-container"]} onClick={() => handleOpenQuestion(item)}>
                                <div className={styles["faq-question-section"]}>
                                    <div className={styles["faq-question-text"]}>
                                        {item.question}
                                    </div>
                                    <div
                                        className={styles["faq-extend"]}
                                    >
                                        { item.open
                                            ? <UpIcon className={styles["faq-arrow"]}/>
                                            : <DownIcon className={styles["faq-arrow"]}/>
                                        }
                                    </div>
                                </div>
                                <div
                                    className={`${styles["faq-answer-section"]} ${item.open ? styles["active"] : ""}`}
                                >
                                    <div className={styles["faq-answer-text"]}>
                                        {item.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={`${styles["no-results"]} ${styles["hidden"]}`}>
                    No questions / answers match your query.
                </div>
                <div className={styles["faq-bottom"]}>
                    <div className={styles["faq-bottom-text"]}>
                        Didn't find what you were looking for?
                    </div>
                    <ButtonLink to="/contact" text="Contact Us" />
                </div>
            </div>
        </MainLayout>
    );
}
