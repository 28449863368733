import styles from './ContentLayout.module.scss';

const ContentLayout = ({ children }) => {
    return (
        <div className={styles.contentLayout}>
            { children }
        </div>
    )
}

export { ContentLayout };