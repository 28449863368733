import axios from "axios";

const API_URL = 'https://vp-customs.com/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const addNewsletterEntry = async (email) => {
    console.log(email);
    try {
        const response = await api.post('/newsletter', { email });
        return response.data;
    } catch (err) {
        console.error('Error adding newsletter entry: ', err);
        throw err;
    }
}

const addContactRequest = async (body) => {
    try {
        const response = await api.post('/contact', body);
        return response.data;
    } catch (err) {
        console.error('Error adding contact request: ', err);
    }
}

const addCustomRequest = async (body) => {
    try {
        const response = await api.post('/request', body);
        return response.data;
    } catch (err) {
        console.error('Error adding custom shop request: ', err);
    }
}

export { addNewsletterEntry, addContactRequest, addCustomRequest };